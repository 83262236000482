/* eslint-disable */
import React, { useEffect, useRef, useState } from "react";
import styles from "./ChatComponent.module.scss";
import { Paper, Button, InputBase, Divider, IconButton, Avatar, Chip, Backdrop, CircularProgress } from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import SendIcon from "@mui/icons-material/Send";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AssignTopicModal from "../Modals/AssignTopicModal/AssignTopicModal";
import ChatMessagesHistory from "../ChatMessagesHistory/ChatMessagesHistory";
import ModalComponent from "../Modals/ModalComponent/ModalComponent";
import ChatDropDownMenu from "../ChatActions/ChatDropDownMenu/ChatDropDownMenu";
import TeamManagementModal from "../Modals/TeamManagementModal/TeamManagementModal";
import ChangeAssigneeModal from "../Modals/ChangeAssigneeModal/ChangeAssigneeModal";
import CompleteTopicModal from "../Modals/CompleteTopicModal/CompleteTopicModal";
import Notes from "../ChatActions/Notes/Notes";
import Activity from "../ChatActions/Activity/Activity";
import { supportSocketEmit } from "../../actions/support";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { getCurrentTicket, ticketActions, sendReminder, editCustomerName } from "../../actions/tickets";
import { listVehicles, getUserInfo } from "../../actions/amarantFields";
import { uploadMedia } from "../../utils/media";
import { getCurrentSystemUsers } from "../../actions/users";
import { format } from "date-fns";
import { User } from "../../utils/Authenticate";
import Search from "../ChatActions/Search/Search";
import PaymentModal from "../Modals/PaymentModal/PaymentModal";
import Swal from "sweetalert2";
import ChangeTopicNameModal from "../Modals/ChangeTopicNameModal/ChangeTopicNameModal";
import { useNavigate } from "react-router-dom";
import TemplatesModal from "../Modals/TemplatesModal/TemplatesModal";
import LabelsModal from "../Modals/LabelsModal/LabelsModal";
import useKeyPress from "../../utils/KeyboardShortcuts";
import { toast } from "react-toastify";
import MobileChatComponent from "./MobileChatComponent";
import MobileChatActions from "../Modals/MobileChatActions/MobileChatActions";
import ForTopicModal from "../Modals/ForTopicModal/ForTopicModal";
import { nanoid } from "nanoid";
import { listSystems } from "../../actions/system";

function ChatComponent({ supportSocketEmit, messagesById, currentTicket, getCurrentTicket, ticketActions, getCurrentSystemUsers, currentSystemUsers, sendReminder, token, listSystems, systemsList, getUserInfo, editCustomerName }) {
  const { slug, topicId } = useParams();
  const currentUser = User.loadLocalStorageItems();
  useEffect(() => {
    getCurrentTicket({ id: topicId });
    listSystems({
      usePagination: false,
      onSuccess: (res) => {},
    });
    getCurrentSystemUsers({
      system: slug,
      noPagination: true,
      onSuccess: () => {},
    });
    supportSocketEmit({
      action: "joinTicket",
      payload: {
        ticket: topicId,
      },
    });
    supportSocketEmit({
      action: "nextMessages",
      payload: {
        pageNumber: 1,
        pageSize: 20, // change to constant
        ticket: topicId,
      },
    });
    return () => {
      supportSocketEmit({
        action: "leaveAll",
      });
    };
  }, [getCurrentTicket]);

  const [openActivity, setOpenActivity] = useState(false);
  const handleOpenActivity = () => setOpenActivity(true);
  const handleCloseActivity = () => setOpenActivity(false);

  const [requestRating, setRequestRating] = useState(false);

  const [selectedFile, setSelectedFile] = useState([]);
  const navigate = useNavigate();

  function handleKeyDown(event) {
    if (selectedFile.length && !currentMessage.length && event.key === "Backspace") {
      setSelectedFile(selectedFile.slice(0, selectedFile.length - 1));
    }
  }

  const onChangeFileSelection = (event) => {
    let newFile = [...selectedFile];
    if (newFile.indexOf(event.target.files[0]) === -1) {
      const blob = event.target.files[0]?.slice(0, event.target.files[0]?.size, event.target.files[0]?.type);
      const updatedFile = new File([blob], `${nanoid()}.${event.target.files[0].type?.split("/")[1]}`, { type: event.target.files[0]?.type });
      newFile = [...newFile, updatedFile];
    }
    setSelectedFile(newFile);
  };

  //modals
  const [completed, setCompleted] = useState(false);

  const [openSearchBar, setOpenSearchBar] = useState(false);
  const handleOpenSearchBar = () => setOpenSearchBar(true);
  const handleCloseSearchBar = () => setOpenSearchBar(false);

  const [openNotes, setOpenNotes] = useState(false);
  const handleOpenNotes = () => setOpenNotes(true);
  const handleCloseNotes = () => setOpenNotes(false);

  const [openCompleteTopicModal, setOpenCompleteTopicModal] = useState(false);
  const handleOpenCompleteTopicModal = () => setOpenCompleteTopicModal(true);
  const handleCloseCompleteTopicModal = () => setOpenCompleteTopicModal(false);

  const [openTeamManagementModal, setOpenTeamManagementModal] = useState(false);
  const handleOpenTeamManagementModal = () => setOpenTeamManagementModal(true);
  const handleCloseTeamManagementModal = () => setOpenTeamManagementModal(false);

  const [openChangeAssigneeModal, setOpenChangeAssigneeModal] = useState(false);
  const handleOpenChangeAssigneeModal = () => setOpenChangeAssigneeModal(true);
  const handleCloseChangeAssigneeModal = () => setOpenChangeAssigneeModal(false);

  const [openTemplatesModal, setOpenTemplatesModal] = useState(false);
  const handleOpenTemplatesModal = () => setOpenTemplatesModal(true);
  const handleCloseTemplatesModal = (event, reason) => {
    setOpenTemplatesModal(false);
  };

  const [openVoteModal, setOpenVoteModal] = useState(false);

  const [openChangeTopicNameModal, setOpenChangeTopicNameModal] = useState(false);
  const handleOpenChangeTopicNameModal = () => setOpenChangeTopicNameModal(true);
  const handleCloseChangeTopicNameModal = () => setOpenChangeTopicNameModal(false);

  const [openPaymentModal, setOpenPaymentModal] = useState(false);
  const handleOpenPaymentModal = () => setOpenPaymentModal(true);
  const handleClosePaymentModal = () => setOpenPaymentModal(false);

  const [forTopicModal, setForTopicModal] = useState(false);

  const [openLabelsModal, setOpenLabelsModal] = useState(false);
  const handleOpenLabeslModal = () => setOpenLabelsModal(true);
  const handleCloseLabelsModal = () => setOpenLabelsModal(false);

  const [actionsAnchor, setActionsAnchor] = useState(null);
  const openActions = Boolean(actionsAnchor);
  const handleOpenActions = (event) => {
    setActionsAnchor(event.currentTarget);
  };
  const handleCloseActions = () => {
    setActionsAnchor(null);
  };

  const [replyAction, setReplyAction] = useState({ open: false, message: null });

  const fileInput = useRef(null);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  //chat actions
  const [searchTags, setSearchTags] = useState([]);
  const [matches, setMatches] = useState([]);

  const handleRequestVote = () => {
    const payload = {
      action: "sendMessage",
      payload: {
        ticket: topicId,
        text: "",
        variant: "vote",
      },
    };
    setRequestRating(true);
    supportSocketEmit(payload);
    Swal.fire("Успешно изпратихте запитване за оценка.", "", "success");
  };

  const handleSetAssignee = (assignee) => {
    const payload = {
      payloadAction: { responsible: assignee },
      id: topicId,
      onSuccess: () => {
        getCurrentTicket({ id: topicId });
      },
    };
    ticketActions(payload);
    Swal.fire("Успешно променихте отговорника.", "", "success").then(() => {});
  };

  const handleArchiveTicket = () => {
    const payload = {
      payloadAction: { archived: true },
      id: topicId,
    };
    ticketActions(payload);
    Swal.fire("Успешно архивирахте темата.", "", "success");
  };

  const handleSetTeam = (team) => {
    const preFlightParse = team.map(({ _id }) => _id);
    const payload = {
      payloadAction: { team: preFlightParse },
      id: topicId,
      onSuccess: () => {},
    };
    ticketActions(payload);
    getCurrentTicket({ id: topicId });
    Swal.fire("Успешно променихте екипа.", "", "success");
  };

  const handleCompleted = () => {
    const payload = {
      payloadAction: { completed: true },
      id: topicId,
      onSuccess: () => {},
    };
    ticketActions(payload);
    getCurrentTicket({ id: topicId });
    Swal.fire("Успешно завършихте темата.", "", "success");
  };

  const handleUnreadTicket = () => {
    const payload = {
      payloadAction: { readed: false },
      id: topicId,
      onSuccess: () => {},
    };
    ticketActions(payload);
    Swal.fire("Успешно отбелязахте темата като непрочетена.", "", "success");
  };

  const handleOpenTicket = () => {
    const payload = {
      payloadAction: { completed: false },
      id: topicId,
    };
    ticketActions(payload);
    getCurrentTicket({ id: topicId });
    Swal.fire("Успешно отворихте темата.", "", "success");
  };

  const handleReminder = () => {
    const payload = {
      tickets: [topicId],
    };
    sendReminder(payload);
    Swal.fire("Съобщението е изпратено успешно.", "", "success");
  };

  const [openLoading, setOpenLoading] = useState(false);
  const handleCloseLoading = () => {
    setOpenLoading(false);
  };
  const handleToggleLoading = () => {
    setOpenLoading(!openLoading);
  };
  const [currentMessage, setCurrentMessage] = useState("");
  const messages = messagesById?.[topicId]?.docs;
  const nextPage = messagesById?.[topicId]?.nextPage;

  const handleChange = (event) => {
    setCurrentMessage(event.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (topicId && currentMessage !== "") {
      supportSocketEmit({
        action: "sendMessage",
        payload: {
          ticket: topicId,
          text: currentMessage,
          ...(replyAction.open && replyAction.message && { replyToMessage: replyAction.message?._id }),
        },
      });
      setReplyAction({ open: false, message: null });
    }
    if (selectedFile.length > 0) {
      if (selectedFile.length > 4) {
        return alert("You can only upload 4 files at a time");
      }
      const onSuccess = (res) => {
        selectedFile.map((file, index) => {
          const event = {
            action: "sendMessage",
            payload: {
              ticket: topicId,
              text: res[index]?.location,
              variant: file.type.includes("image") ? "photo" : file.type.includes("video") ? "video" : "document",
            },
          };
          supportSocketEmit(event);
        });
      };
      handleToggleLoading();
      uploadMedia(selectedFile, onSuccess, handleCloseLoading, systemsList.filter((sys) => sys._id === slug)?.[0]?.name);
    }
    setSelectedFile([]);
    setCurrentMessage("");
  };

  const completedByUser = currentSystemUsers?.find((user) => currentTicket?.completedBy === user?._id);
  const archivedByUser = currentSystemUsers?.find((user) => currentTicket?.archivedBy === user?._id);

  const handleNavigate = () => {
    if (slug === "61ee8b87a07ddf1eb8268265") {
      getUserInfo({
        customerSupportId: currentTicket?.customer?._id,
        onSuccess: (res) => {
          if (res?.fullName !== currentTicket?.customer?.name) {
            editCustomerName({
              id: currentTicket?.customer?._id,
              body: {
                name: res?.fullName,
              },
              onSuccess: (res) => {
                navigate(`/amarant/user/${currentTicket.customer._id}`);
              },
            });
          } else navigate(`/amarant/user/${currentTicket.customer._id}`);
        },
      });
    } else navigate(`/immotech/user/${currentTicket.customer._id}`);
  };

  const handleNavigateOffer = () => {
    navigate(`/createOffer/${topicId}`);
  };

  const onKeyPress = (event) => {
    if (event.key === "m" || event.key === "м") {
      currentTicket.responsible ? handleOpenChangeAssigneeModal() : handleOpen();
    } else if (event.key === "t" || event.key === "т") handleOpenChangeTopicNameModal();
    else if (event.key === "e" || event.key === "е") setOpenVoteModal(true);
    else if (event.key === "s" || event.key === "с") handleOpenCompleteTopicModal();
    else if (event.key === "z" || event.key === "з") handleOpenTemplatesModal();
    else if (event.key === "q" || event.key === "ч" || event.key === "я") {
      currentTicket.responsible ? (currentTicket.responsible._id.includes(currentUser.user._id) ? toast.error("You are already assigned!") : handleSetAssignee(currentUser.user._id)) : handleSetAssignee(currentUser.user._id);
    } else if (event.key === "i" || event.key === "и") {
      handleNavigate();
    }
  };

  useKeyPress(["m", "t", "e", "s", "z", "q", "i", "ч", "я", "м", "т", "е", "с", "з", "и"], onKeyPress);

  const [handleOpenMobileActions, setHandleOpenMobileActions] = useState(false);

  return (
    <div className={styles["chat-container"]}>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={openLoading} onClick={handleCloseLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {window.innerWidth < 900 ? (
        <MobileChatComponent currentTicket={currentTicket} setHandleOpenMobileActions={setHandleOpenMobileActions} slug={slug} handleOpen={handleOpen} handleNavigate={handleNavigate} />
      ) : (
        <Paper elevation={2} className={styles["chat-header"]} style={{ zIndex: "10" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <h5
              style={{
                marginRight: "10px",
                whiteSpace: "nowrap",
                fontSize: "14px",
                display: currentTicket?.customer?.paidUser === true && "flex",
              }}
            >
              {currentTicket?.customer?.paidUser === true && <div className={styles["special-user"]} />}
              {currentTicket?.name?.length > 50 ? currentTicket?.name?.slice(0, 50) + "..." : currentTicket?.name}
            </h5>
            <Divider orientation="vertical" sx={{ height: 40, m: 0.5, backgroundColor: "#0DC1CE" }} />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: "10px",
              }}
            >
              {slug === "61ee8b87a07ddf1eb8268265" || slug === "61ee8bcaa07ddf1eb8268269" ? <div className={styles["user-icon"]} style={{ marginRight: "10px" }} onClick={handleNavigate} /> : null}
              <div style={{ display: "flex", alignItems: "center", whiteSpace: "nowrap" }}>
                Потребител: <b style={{ margin: "0px 5px" }}>{currentTicket?.customer?.name === undefined ? currentTicket?.customer?.phoneNumber : currentTicket?.customer?.name}</b>
                <Divider orientation="vertical" sx={{ height: 40, m: 0.5, backgroundColor: "#0DC1CE" }} />
                {currentTicket?.customer?.phoneNumber ? (
                  <span style={{ marginLeft: "10px" }}>
                    Телефонен номер: <b>{currentTicket?.customer?.phoneNumber || "---"}</b>
                  </span>
                ) : null}
              </div>
            </div>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            {currentTicket?.responsible !== null ? (
              <div className={styles["current-assignee"]}>
                <p>Отговорник:</p>
                <Avatar src={currentTicket?.responsible?.avatar} />
                <p>{currentTicket?.responsible?.name}</p>
                <p>
                  Екип: <b>{currentTicket?.team?.length}</b>
                </p>
              </div>
            ) : (
              <Button
                onClick={handleOpen}
                disableElevation={true}
                disableRipple
                disableFocusRipple
                variant="contained"
                sx={{
                  color: "white",
                  backgroundColor: "#0DC1CE",
                  cursor: "pointer",
                  "&:hover": {
                    backgroundColor: "#0DC1CE",
                  },
                }}
              >
                Назначи тема(M)
              </Button>
            )}
            <MoreVertIcon onClick={handleOpenActions} />
            <ChatDropDownMenu
              handleOpenSearchBar={handleOpenSearchBar}
              handleArchiveTicket={handleArchiveTicket}
              handleUnreadTicket={handleUnreadTicket}
              handleRequestVote={handleRequestVote}
              handleOpenTicket={handleOpenTicket}
              handleOpenActivity={handleOpenActivity}
              setRequestRating={setRequestRating}
              completed={currentTicket?.completedAt}
              setCompleted={setCompleted}
              handleOpenNotes={handleOpenNotes}
              handleOpenChangeAssigneeModal={handleOpenChangeAssigneeModal}
              handleOpenTeamManagementModal={handleOpenTeamManagementModal}
              handleOpenChangeTopicNameModal={handleOpenChangeTopicNameModal}
              handleOpenCompleteTopicModal={handleOpenCompleteTopicModal}
              handleOpenTemplatesModal={handleOpenTemplatesModal}
              handleOpenPaymentModal={handleOpenPaymentModal}
              handleOpenLabelsModal={handleOpenLabeslModal}
              handleReminder={handleReminder}
              handleNavigateOffer={handleNavigateOffer}
              open={openActions}
              systemId={slug}
              anchorEl={actionsAnchor}
              handleClose={handleCloseActions}
              token={token}
              setOpenVoteModal={setOpenVoteModal}
              currentTicket={currentTicket}
              mobile={window.innerWidth < 900 && true}
              topicId={topicId}
            />
          </div>
        </Paper>
      )}
      {currentTicket?.labels?.length > 0 && window.innerWidth > 900 ? (
        <Paper elevation={1} className={styles["chat-header"]} style={{ zIndex: "1", alignItems: "flex-start", height: "40px" }}>
          <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
            <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
              Етикети:{" "}
              {currentTicket?.labels?.map((label, i) => {
                return (
                  <React.Fragment key={i}>
                    <b style={{ marginLeft: "1%", whiteSpace: "nowrap" }}>{label?.name}</b>
                    <Divider
                      orientation="vertical"
                      sx={{
                        height: 16,
                        m: 0.5,
                        backgroundColor: "#0DC1CE",
                        display: i === currentTicket?.labels?.length - 1 && "none",
                      }}
                    />
                  </React.Fragment>
                );
              })}
            </div>
          </div>
        </Paper>
      ) : null}

      {openSearchBar && <Search setSearchTags={setSearchTags} matches={matches} handleCloseSearchBar={handleCloseSearchBar} />}
      <div className={styles["messages-container"]}>
        <div className={styles["messages-history"]}>
          <ChatMessagesHistory setMatches={setMatches} searchTags={searchTags} currentTicket={currentTicket} messages={messagesById?.[topicId]?.docs} hasNextPage={messagesById?.[topicId]?.hasNextPage} totalPages={messagesById?.[topicId]?.totalPages} nextPage={nextPage} slug={slug} setReplyAction={setReplyAction} supportSocketEmit={supportSocketEmit} />
        </div>
        {replyAction.open && replyAction.message && (
          <div className={styles["reply-action-container"]}>
            <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
              <div className={styles["reply-icon"]} />
              <p style={{ fontStyle: "italic" }}>
                Отговор на съобщение: <span style={{ fontWeight: "bold" }}>{replyAction.message?.text?.length > 100 ? replyAction.message?.text?.slice(0, 100) + "..." : replyAction.message?.text}</span>
              </p>
              <div className={styles["close-reply-action-icon"]} onClick={() => setReplyAction({ open: false, message: null })} />
            </div>
          </div>
        )}
        <div className={styles["form-container"]}>
          {currentTicket?.completedAt || currentTicket?.archivedAt ? (
            <>
              {currentTicket?.completedAt && !currentTicket?.archivedAt && (
                <p style={{ textAlign: "center", fontWeight: "bold" }}>
                  Тази тема е отбелязана като завършена от {completedByUser?.name === currentUser?.user?.name ? "Вас" : completedByUser?.name} на: {format(new Date(currentTicket?.completedAt), "dd.MM.yyyy")} |{format(new Date(currentTicket?.completedAt), "HH:mm")}
                </p>
              )}
              {currentTicket?.archivedAt && (
                <p style={{ textAlign: "center", fontWeight: "bold" }}>
                  Тази тема е отбелязана като архивирана от {archivedByUser?.name === currentUser?.user?.name ? "Вас" : archivedByUser?.name} на: {format(new Date(currentTicket?.archivedAt), "dd.MM.yyyy")} |{format(new Date(currentTicket?.archivedAt), "HH:mm")}
                </p>
              )}
            </>
          ) : (
            <>
              <Paper
                onSubmit={handleSubmit}
                component={"form"}
                sx={{
                  p: "2px 4px",
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <InputBase
                  multiline
                  onKeyDown={handleKeyDown}
                  style={{
                    display: window.innerWidth < 900 && "flex",
                    flexWrap: window.innerWidth < 900 && "wrap",
                  }}
                  onKeyPress={(event) => {
                    if (event.key === "Enter" && event.shiftKey) handleSubmit(event);
                  }}
                  startAdornment={selectedFile.map((file, i) => (
                    <Chip
                      key={file.name || i}
                      tabIndex={-1}
                      label={file.name.length > 40 ? file.name.slice(0, 39) + "..." : file.name}
                      sx={{
                        marginRight: "5px",
                        backgroundColor: "#0DC1CE",
                        color: "#FFFFFF",
                        borderRadius: "5px",
                      }}
                    />
                  ))}
                  sx={{ ml: 1, flex: 1 }}
                  onChange={handleChange}
                  value={currentMessage}
                />
                <Divider sx={{ height: 27, m: 0.5 }} orientation="vertical" />
                <IconButton disableRipple disableFocusRipple className={styles["filter-button"]} onClick={() => fileInput.current.click()}>
                  <AttachFileIcon style={{ color: "#0DC1CE" }} />
                  <input onChange={onChangeFileSelection} ref={fileInput} type="file" style={{ display: "none" }} />
                </IconButton>{" "}
              </Paper>
              <SendIcon
                onClick={handleSubmit}
                fontSize="large"
                style={{
                  color: "#01D088",
                  marginLeft: window.innerWidth < 900 && "2%",
                }}
              />
            </>
          )}
        </div>
      </div>
      <ModalComponent open={open} handleClose={handleClose} mobileModal={window.innerWidth < 900 && true} children={<AssignTopicModal handleClose={handleClose} setAssignee={handleSetAssignee} getCurrentTicket={getCurrentTicket} topicId={topicId} getCurrentSystemUsers={getCurrentSystemUsers} slug={slug} />} />
      <ModalComponent
        open={handleOpenMobileActions}
        handleClose={() => {
          setHandleOpenMobileActions(false);
        }}
        mobileActions={true}
        children={
          <MobileChatActions
            handleOpenSearchBar={handleOpenSearchBar}
            handleArchiveTicket={handleArchiveTicket}
            handleUnreadTicket={handleUnreadTicket}
            handleRequestVote={handleRequestVote}
            handleOpenTicket={handleOpenTicket}
            handleOpenActivity={handleOpenActivity}
            setRequestRating={setRequestRating}
            completed={currentTicket?.completedAt}
            setCompleted={setCompleted}
            handleOpenNotes={handleOpenNotes}
            handleOpenChangeAssigneeModal={handleOpenChangeAssigneeModal}
            handleOpenTeamManagementModal={handleOpenTeamManagementModal}
            handleOpenChangeTopicNameModal={handleOpenChangeTopicNameModal}
            handleOpenCompleteTopicModal={handleOpenCompleteTopicModal}
            handleOpenTemplatesModal={handleOpenTemplatesModal}
            handleOpenPaymentModal={handleOpenPaymentModal}
            handleOpenLabelsModal={handleOpenLabeslModal}
            handleReminder={handleReminder}
            open={openActions}
            systemId={slug}
            anchorEl={actionsAnchor}
            token={token}
            currentTicket={currentTicket}
            handleClose={() => setHandleOpenMobileActions(false)}
            setForTopicModal={setForTopicModal}
          />
        }
      />
      <ModalComponent
        open={openPaymentModal}
        handleClose={handleClosePaymentModal}
        children={
          <PaymentModal
            handleClose={handleClosePaymentModal}
            currentTicket={currentTicket}
            //setAssignee={handleSetAssignee}
          />
        }
      />
      <ModalComponent
        open={openTeamManagementModal}
        handleClose={handleCloseTeamManagementModal}
        mobileModal={window.innerWidth < 900 && true}
        children={<TeamManagementModal team={currentTicket?.team} getCurrentTicket={getCurrentTicket} topicId={topicId} handleSetTeam={handleSetTeam} handleClose={handleCloseTeamManagementModal} getCurrentSystemUsers={getCurrentSystemUsers} slug={slug} />}
      />
      <ModalComponent
        open={openChangeAssigneeModal}
        handleClose={handleCloseChangeAssigneeModal}
        mobileModal={window.innerWidth < 900 && true}
        children={<ChangeAssigneeModal assignee={currentTicket?.responsible} setAssignee={handleSetAssignee} handleClose={handleCloseChangeAssigneeModal} getCurrentTicket={getCurrentTicket} topicId={topicId} getCurrentSystemUsers={getCurrentSystemUsers} slug={slug} />}
      />
      <ModalComponent open={openChangeTopicNameModal} handleClose={handleCloseChangeTopicNameModal} children={<ChangeTopicNameModal getCurrentTicket={getCurrentTicket} topicId={topicId} currentTicket={currentTicket} handleClose={handleCloseChangeTopicNameModal} />} />
      <ModalComponent open={openCompleteTopicModal} handleClose={handleCloseCompleteTopicModal} children={<CompleteTopicModal handleCompleted={handleCompleted} handleClose={handleCloseCompleteTopicModal} />} />
      <ModalComponent
        open={openVoteModal}
        handleClose={() => {
          setOpenVoteModal(false);
        }}
        children={
          <CompleteTopicModal
            handleCompleted={handleRequestVote}
            vote={true}
            handleClose={() => {
              setOpenVoteModal(false);
            }}
          />
        }
      />
      <ModalComponent open={openLabelsModal} handleClose={handleCloseLabelsModal} special={true} children={<LabelsModal handleClose={handleCloseLabelsModal} slug={slug} />} />
      <ModalComponent open={openTemplatesModal} handleClose={handleCloseTemplatesModal} special={true} children={<TemplatesModal handleClose={handleCloseTemplatesModal} slug={slug} setCurrentMessage={setCurrentMessage} />} />
      <ModalComponent
        open={forTopicModal}
        handleClose={() => {
          setForTopicModal(false);
        }}
        mobileModal={window.innerWidth < 900 && true}
        children={
          <ForTopicModal
            handleClose={() => {
              setForTopicModal(false);
            }}
            currentTicket={currentTicket}
            slug={slug}
            handleNavigate={handleNavigate}
          />
        }
      />
      <Activity open={openActivity} handleClose={handleCloseActivity} />
      <Notes open={openNotes} handleClose={handleCloseNotes} />
    </div>
  );
}

const mapStateToProps = (state) => ({
  tickets: state.support.tickets,
  token: state.login.token,
  messagesById: state.support.messagesById,
  currentSystemUsers: state.users.currentSystemUsers,
  currentTicket: state.tickets.currentTicket,
  systemsList: state.systems.systemsList,
});

const mapDispatchToProps = (dispatch) => ({
  getCurrentTicket: (payload) => dispatch(getCurrentTicket(payload)),
  editCustomerName: (payload) => dispatch(editCustomerName(payload)),
  getUserInfo: (payload) => dispatch(getUserInfo(payload)),
  getCurrentSystemUsers: (payload) => dispatch(getCurrentSystemUsers(payload)),
  ticketActions: (payload) => dispatch(ticketActions(payload)),
  sendReminder: (payload) => dispatch(sendReminder(payload)),
  supportSocketEmit: (payload) => dispatch(supportSocketEmit(payload)),
  listVehicles: (payload) => dispatch(listVehicles(payload)),
  listSystems: (payload) => dispatch(listSystems(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChatComponent);
