/* eslint-disable */
import { format } from "date-fns";
import React, { useRef, useCallback, useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Ratings from "../ChatActions/Ratings/Ratings";
import { clearSupportMessage, supportSocketEmit } from "../../actions/support";
import styles from "./ChatMessagesHistory.module.scss";
import Highlight from "../ChatActions/Search/Highlight";
import Timestamp from "../ChatActions/Timestamp/Timestamp";
import Swal from "sweetalert2";
import moment from "moment";
import { getPresignedUrlView } from "../../utils/files";
import Popup from "reactjs-popup";

const payment_messages_to_exclude = ["651555104e3ce3152db7e2d3"];

function ChatMessagesHistory({ messages, supportSocketEmit, nextPage, searchTags, setMatches, hasNextPage, slug, setReplyAction }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { topicId } = useParams();
  const { messageToBeScrolled } = useSelector(({ support }) => support);
  const observer = useRef(null);
  const [canceled, setCanceled] = useState(false);
  const [imageUrls, setImageUrls] = useState({});
  const [showReplyButton, setShowReplyButton] = useState({ open: false, _id: null });

  const handleLoadMessagesRef = useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting === true && hasNextPage === true) {
          //nextPage < totalPages
          const event = {
            action: "nextMessages",
            payload: {
              ticket: topicId,
              pageSize: 20,
              pageNumber: nextPage,
            },
          };
          supportSocketEmit(event);
        }
      });
      if (node) observer.current.observe(node);
    },
    [nextPage]
  );

  useEffect(() => {
    console.log(slug);
    if (slug !== "61ee8b87a07ddf1eb8268265") return;
    const fetchPresignedUrls = async () => {
      const urls = {};
      for (const message of messages) {
        if (message?.variant !== "text" && message?.variant !== "vote" && message?.variant !== "payment" && message?.variant !== "offer" && (message?.text?.startsWith("https://amarant2.s3") || message?.text?.startsWith("https://amarant-rpa.s3") || message?.text?.startsWith("https://amarant-docs.s3"))) {
          try {
            const url = await getPresignedUrlView(message.text);
            urls[message._id] = url;
          } catch (error) {
            console.error(`Error fetching presigned URL for message ${message._id}`, error);
          }
        }
      }
      setImageUrls(urls);
    };
    if (messages?.length > 0) {
      fetchPresignedUrls();
    }
  }, [messages]);

  const handleScrollToRepliedMessage = (_id) => {
    const foundMessage = document.getElementById(_id);
    if (foundMessage) foundMessage.scrollIntoView({ behavior: "smooth" });
    else {
      localStorage.setItem("scrollToMessage", _id);
      supportSocketEmit({
        action: "nextMessages",
        payload: {
          noPagination: true,
          ticket: topicId,
        },
      });
    }
  };

  useEffect(() => {
    if (messageToBeScrolled) handleScrollToRepliedMessage(messageToBeScrolled);
    dispatch(clearSupportMessage());
  }, [messageToBeScrolled]);

  return (
    <>
      {messages?.map((message, index) => (
        <div
          ref={handleLoadMessagesRef}
          className={`${styles.message} ${message?.type !== "Customer" ? styles.me : ""}`}
          id={message?._id}
          key={message?._id}
          onMouseEnter={() => {
            if (slug === "61ee8b87a07ddf1eb8268265") setShowReplyButton({ open: true, _id: message?._id });
          }}
          onMouseLeave={() => {
            if (slug === "61ee8b87a07ddf1eb8268265") setShowReplyButton({ open: false, _id: null });
          }}
        >
          {message?.variant == "payment" && (
            <>
              <p className={styles.time}>{format(new Date(message?.createdAt), "HH:mm")}</p>
              <div className={styles["message-body"]} style={{ textAlign: "end" }}>
                <Highlight setMatches={setMatches} tags={searchTags}>
                  <p>ИНИЦИИРАНО ПЛАЩАНЕ</p>
                  <p>
                    Сума <b>{(message?.metaData?.amount / 100).toFixed(2)} лв.</b>
                  </p>
                  <p>
                    Основание <b>{message?.text}</b>
                  </p>
                  <p>
                    Статус: <b className={`${styles["status"]} ${message?.metaData?.status === "paid" || payment_messages_to_exclude.includes(message?._id) ? styles["green"] : null}`}>{message?.paymentCanceled === true ? "Анулирано" : message?.metaData?.status === "paid" || payment_messages_to_exclude.includes(message?._id) ? "Платено" : "Неплатено"}</b>
                  </p>
                </Highlight>
              </div>
              {message?.paymentCanceled === false && message?.metaData?.status !== "paid" && !payment_messages_to_exclude.includes(message?._id) ? (
                <div
                  className={styles["deletePayment"]}
                  onClick={() => {
                    supportSocketEmit({
                      action: "cancelPayment",
                      payload: {
                        message: message?._id,
                      },
                    });

                    const event = {
                      action: "nextMessages",
                      payload: {
                        ticket: topicId,
                        pageSize: 200,
                        pageNumber: nextPage - 1,
                      },
                    };
                    supportSocketEmit(event);
                    Swal.fire("Плащането беше анулирано успешно", "", "success");
                    setCanceled(true);
                  }}
                >
                  <div className={styles["bin"]} />
                  <b style={{ color: "#8244EB" }}>Анулирай плащане</b>
                </div>
              ) : null}
              {messages?.filter((mess) => mess?.type === "User" && mess?.variant === "payment").length && (message?.metaData?.status === "paid" || payment_messages_to_exclude.includes(message?._id)) ? (
                <div
                  className={styles["deletePayment"]}
                  onClick={() => navigate(`/addPolicyToUser/${topicId}/${message?.metaData?.customerSupportPayment?.customerSupportId}/${messages?.filter((mess) => mess?.variant === "offer" && mess?.offer?.accepted)?.length ? messages?.filter((mess) => mess?.variant === "offer" && mess?.offer?.accepted)?.[0]?.offer?._id : "no-offer"}`)}
                >
                  <div className={styles["add-policy"]} />
                  <b style={{ color: "#01D088" }}>Добави полица към профила</b>
                </div>
              ) : null}
            </>
          )}
          {message?.variant == "offer" && (
            <>
              {message?.type !== "Customer" && <p className={styles.time}>{format(new Date(message?.createdAt), "HH:mm")}</p>}

              <div className={styles["message-body"]} style={{ textAlign: message?.type !== "Customer" && "end" }}>
                <Highlight setMatches={setMatches} tags={searchTags}>
                  <div
                    style={{
                      margin: "5px 0px",
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: message?.type === "Customer" && "row-reverse",
                    }}
                  >
                    {message?.offer?.accepted ? (
                      <p style={{ color: "#01D088", fontSize: "12px" }}>
                        <b>ИЗБРАНА ОФЕРТА</b>
                      </p>
                    ) : (
                      <div
                        className={`${styles["edit-offer-icon"]}`}
                        onClick={() => {
                          navigate(`/editOffer/${topicId}/${message?.offer?._id}`);
                        }}
                      />
                    )}
                    <b style={{ textTransform: "uppercase" }}>{message?.offer?.insuranceCompanyName}</b>
                  </div>
                  <p style={{ margin: "5px 0px" }}>
                    Застрахователна сума: <b>{message?.offer?.insuranceSum} лв.</b>
                  </p>
                  {message?.offer?.originalPremium ? (
                    <>
                      <p style={{ margin: "5px 0px" }}>
                        Премия преди остъпка <b>{message?.offer?.originalPremium} лв.</b>
                      </p>
                      <p style={{ margin: "5px 0px" }}>
                        Отстъпка{" "}
                        <b>
                          {(message?.offer?.originalPremium - message?.offer?.premium).toFixed(2)} лв. {`(${Math.round(100 - (message?.offer?.premium / message?.offer?.originalPremium) * 100)} %)`}
                        </b>
                      </p>
                      <p style={{ margin: "5px 0px" }}>
                        Премия след остъпка <b>{message?.offer?.premium} лв.</b>
                      </p>
                    </>
                  ) : (
                    <p style={{ margin: "5px 0px" }}>
                      Премия <b>{message?.offer?.premium} лв.</b>
                    </p>
                  )}

                  <p style={{ margin: "5px 0px" }}>
                    Вноска 1: <b>{message?.offer?.installmentOneSum?.toFixed(2) || "---"} лв.</b>
                  </p>
                  <p style={{ margin: "5px 0px" }}>
                    Вноска 2: <b>{message?.offer?.installmentTwoSum || "---"} лв.</b>
                  </p>
                  <p style={{ margin: "5px 0px" }}>
                    Вноска 3: <b>{message?.offer?.installmentThreeSum || "---"} лв.</b>
                  </p>
                  <p style={{ margin: "5px 0px" }}>
                    Вноска 4: <b>{message?.offer?.installmentFourSum || "---"} лв.</b>
                  </p>
                </Highlight>
              </div>
              {message?.type === "Customer" && <p className={styles.time}>{format(new Date(message?.createdAt), "HH:mm")}</p>}
            </>
          )}
          {message?.variant == "vote" && <Ratings message={message} />}

          {message?.variant !== "text" && message?.variant !== "vote" && message?.variant !== "payment" && message?.variant !== "offer" ? (
            <>
              {message?.type !== "Customer" && <p className={styles.time}>{format(new Date(message?.createdAt), "HH:mm")}</p>}
              {message?.variant === "photo" ? (
                <div
                  className={`${styles["photo-message"]}`}
                  style={{
                    backgroundImage: imageUrls[message._id] ? `url(${imageUrls[message._id]})` : `url(${message?.text})`,
                    backgroundPosition: message?.type === "Customer" ? "left" : "right",
                    cursor: "pointer",
                  }}
                  onClick={() => window.open(imageUrls[message._id] || message?.text)}
                />
              ) : (
                <a className={`${styles["message-body"]} ${styles.media}`} href={imageUrls[message._id] || message?.text} download={imageUrls[message._id] || message?.text} target="_blank" style={{ wordBreak: "break-word", cursor: "pointer" }}>
                  {message?.text}
                </a>
              )}

              {message?.type === "Customer" && <p className={styles.time}>{format(new Date(message?.createdAt), "HH:mm")}</p>}
              <p className={`${styles["message-status"]} ${message?.readByCustomer === true && styles["green"]}`}>{index === 0 && message?.type.includes("User") ? (message?.readByCustomer === true ? `Прочетено ${moment(message?.readByCustomerDate).format("HH:mm")}` : "Доставено") : null}</p>
            </>
          ) : (
            <>
              {message?.variant !== "vote" && message?.variant !== "payment" && message?.variant !== "offer" ? (
                <>
                  {message?.type !== "Customer" ? (
                    <>
                      {message?.variant === "text" && message?.replyToMessage && (
                        <div className={` ${styles["reply-container"]} ${message?.type !== "Customer" && styles["reversed"]}`}>
                          <div style={{ display: "flex", alignItems: "center" }}>
                            <div className={styles["reply-icon"]} />
                            <p style={{ fontStyle: "italic" }}>Отговор на съобщение</p>
                          </div>
                          <div className={styles["reply-message"]} onClick={() => handleScrollToRepliedMessage(message?.replyToMessage?._id)}>
                            {message?.replyToMessage?.text?.length > 40 ? message?.replyToMessage?.text?.slice(0, 40) + "..." : message?.replyToMessage?.text}
                          </div>
                        </div>
                      )}
                      <p className={styles.sender}>{message?.from?.name}</p>
                      {message?.variant === "text" && showReplyButton.open && showReplyButton._id === message?._id && slug === "61ee8b87a07ddf1eb8268265" && (
                        <Popup trigger={<div className={styles["reply-menu-icon"]} />}>
                          {(close) => (
                            <div
                              className={styles["reply-button"]}
                              onClick={() => {
                                setReplyAction({ open: true, message: message });
                                close();
                              }}
                            >
                              <div className={styles["reply-icon"]} />
                              <p>Отговори</p>
                            </div>
                          )}
                        </Popup>
                      )}
                      <div className={styles.time}>{format(new Date(message?.createdAt), "HH:mm")}</div>
                      <div className={styles["message-body"]}>
                        <Highlight setMatches={setMatches} tags={searchTags}>
                          {message?.text}
                        </Highlight>
                      </div>

                      <p className={`${styles["message-status"]} ${message?.readByCustomer === true && styles["green"]}`}>{index === 0 && message?.type.includes("User") ? (message?.readByCustomer === true ? `Прочетено ${moment(message?.updatedAt).format("HH:mm")}` : "Доставено") : null}</p>
                    </>
                  ) : (
                    <>
                      {message?.variant === "text" && message?.replyToMessage && slug === "61ee8b87a07ddf1eb8268265" && (
                        <div className={` ${styles["reply-container"]} ${message?.type !== "Customer" && styles["reversed"]}`}>
                          <div style={{ display: "flex", alignItems: "center" }}>
                            <div className={styles["reply-icon"]} />
                            <p style={{ fontStyle: "italic" }}>Отговор на съобщение</p>
                          </div>
                          <div className={styles["reply-message"]} onClick={() => handleScrollToRepliedMessage(message?.replyToMessage?._id)}>
                            {message?.replyToMessage?.text?.length > 40 ? message?.replyToMessage?.text?.slice(0, 40) + "..." : message?.replyToMessage?.text}
                          </div>
                        </div>
                      )}
                      <p className={styles.sender}>{message?.from?.name}</p>
                      <div className={styles["message-body"]}>
                        <Highlight setMatches={setMatches} tags={searchTags}>
                          {message?.text}
                        </Highlight>
                      </div>
                      <p className={styles.time}>{format(new Date(message?.createdAt), "HH:mm")}</p>
                      {message?.variant === "text" && showReplyButton.open && showReplyButton._id === message?._id && slug === "61ee8b87a07ddf1eb8268265" && (
                        <Popup trigger={<div className={styles["reply-menu-icon"]} />}>
                          {(close) => (
                            <div
                              className={styles["reply-button"]}
                              onClick={() => {
                                setReplyAction({ open: true, message: message });
                                close();
                              }}
                            >
                              <div className={styles["reply-icon"]} />
                              <p>Отговори</p>
                            </div>
                          )}
                        </Popup>
                      )}
                    </>
                  )}
                </>
              ) : null}
            </>
          )}
          {index == 0 || (format(new Date(message?.createdAt), "dd.MM.yyyy") != format(new Date(messages[index - 1]?.createdAt), "dd.MM.yyyy") && <Timestamp time={messages[index - 1]?.createdAt} />)}
        </div>
      ))}
    </>
  );
}

const mapDispatchToProps = (dispatch) => ({
  supportSocketEmit: (payload) => dispatch(supportSocketEmit(payload)),
});

export default connect(null, mapDispatchToProps)(ChatMessagesHistory);
